import { CommonState, Fetcher, API, FetchHelper } from '@primeit/components-vue';
const fetchHelper = new FetchHelper();

const module = {
  actions: {
    FETCH_ALL: async (context, params) => {
      const responseJson = await fetchHelper.fetchAll(process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON, params);
      context.commit('POPULATE_ALL', responseJson);
    },
    FETCH_ALTER_STATUS: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params.id}/status`, {
          headers: {
            'x-company-id': params.company_id,
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_CHILDREN: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params}/children`, {
          method: 'GET',
        });
        if (response.ok) {
          if (response.status === 200) {
            json = await response.json();
          } else {
            json = [];
          }
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_CHILDREN', json);
    },
    FETCH_COMBO: async (context, params) => {
      const responseJson = await fetchHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/combo`,
        params
      );
      context.commit('POPULATE_COMBO', responseJson);
    },
    FETCH_CREATE_EVENT: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params.id}/diagnostic`, {
          body: JSON.stringify(params.body),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });
        json = {
          ...(await response.json()),
          isDiagnostic: true,
        };
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_DELETE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params}`, {
          method: 'DELETE',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_DELETE_ALL: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}?id=${params}`, {
          method: 'DELETE',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_EDIT: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params}/myprofile`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_EDIT', json);
    },
    FETCH_GET_SIGN: async (context, params) => {
      const apiName = 'PersonAPI';
      const path = '/sign';

      const myInit = {
        headers: {
          'x-company-id': params.company_id,
        },
        response: true,
      };

      const json = await API.get(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });
      context.commit('POPULATE_SIGNDATA', json);
    },
    FETCH_HISTORY: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params.id}/history?fromPEP=true${params.filter ? `&filter=${params.filter}` : ''}`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      if (json) {
        context.commit('POPULATE_HISTORY', json);
      }
    },
    FETCH_HISTORY_ONE_APP: async (context, params) => {
      let response;
      let json;

      const id = params.person_id;
      const queryParams = `?item_type=${params.type}&item_id=${params.id}`;

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${id}/history/item${queryParams}`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }

      if (json) {
        context.commit('POPULATE_HISTORY_IN_ONE_APP', json);
      }
    },
    FETCH_HOME: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/home`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_HOME', json);
    },

    FETCH_NEW: async (context, params) => {
      let response;
      let json;
      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/new`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_NEW', json);
    },
    FETCH_NEW_CHILDREN: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params.parent_id}/children`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },

    FETCH_ONE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params}`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_ONE', json);
    },

    FETCH_SAVE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_SIGN: async (context, params) => {
      const apiName = 'PersonAPI';
      const path = '/sign';
      params.is_consultorio = true;

      const myInit = {
        body: params,
        headers: {
          'x-company-id': params.company_id,
        },
        response: true,
      };

      const json = await API.post(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_SIGN_TERM: async (context, params) => {
      const apiName = 'UserAPI';
      const path = '/signterm';

      const myInit = {
        headers: {
          'x-company-id': params.company_id,
        },
        response: true,
      };

      const json = await API.get(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('POPULATE_SIGNTERM', json);
    },
    FETCH_UPDATE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_PERSON}/${params.id}/myprofile`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });

        json = await response.json();
      } catch (e) {
        console.log(e);
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
  },
  getters: {
    GET_ALL: (state) => {
      const list = JSON.parse(JSON.stringify(state.entities));

      list.map((patient) => {
        const cpf = patient.cpf
          ? patient.cpf.slice(0, 3) +
            '.' +
            patient.cpf.slice(3, 6) +
            '.' +
            patient.cpf.slice(6, 9) +
            '-' +
            patient.cpf.slice(9, 11)
          : null;
        const nameComplement = patient.registration || cpf;
        const name = patient.name;
        patient.name_formatted = nameComplement ? name + ' | ' + nameComplement : name;
        return patient;
      });

      return list;
    },
    GET_CHILDREN: (state) => {
      return JSON.parse(JSON.stringify(state.children));
    },
    GET_COMBO: (state) => state.combo,
    GET_EDIT: (state) => {
      return JSON.parse(JSON.stringify(state.edit));
    },
    GET_HISTORY: (state) => state.patient_history,
    GET_HOME: (state) => {
      return state.home;
    },
    GET_NEW: (state) => state.new,
    GET_NEW_CHILDREN: (state) => {
      return JSON.parse(JSON.stringify(state.newChildren));
    },
    GET_ONE: (state) => {
      return JSON.parse(JSON.stringify(state.entity));
    },
    GET_PARENT: (state) => state.entitiesParent,
    GET_SIGNDATA: (state) => state.signData,
    GET_SIGNTERM: (state) => state.signTerms,
  },
  mutations: {
    POPULATE_ALL: (state, payload) => {
      if (payload) {
        state.entities = payload;
      }
    },
    POPULATE_CHILDREN: (state, payload) => {
      if (payload) {
        state.children = payload;
      }
    },
    POPULATE_COMBO: (state, payload) => {
      if (payload) {
        state.combo = payload;
      }
    },
    POPULATE_EDIT: (state, payload) => {
      if (payload) {
        state.edit = payload;
      }
    },
    POPULATE_HISTORY: (state, payload) => {
      if (payload) {
        state.patient_history = payload;
      }
    },
    POPULATE_HISTORY_IN_ONE_APP: (state, payload) => {
      if (payload) {
        payload.alreadyGotInfo = true;
        const index = state.patient_history.findIndex((item) => item.id === payload.id);

        if (index !== -1) {
          state.patient_history[index] = {
            ...payload,
            ...state.patient_history[index],
          };

          const newHistory = JSON.parse(JSON.stringify(state.patient_history));
          state.patient_history = newHistory;
        }
      }
    },
    POPULATE_HOME: (state, payload) => {
      if (payload) {
        state.home = payload;
      }
    },
    POPULATE_NEW: (state, payload) => {
      if (payload) {
        state.new = payload;
      }
    },
    POPULATE_NEW_CHILDREN: (state, payload) => {
      if (payload) {
        state.newChildren = payload;
      }
    },
    POPULATE_ONE: (state, payload) => {
      if (payload) {
        state.entity = payload;
      }
    },
    POPULATE_PARENT: (state, payload) => {
      if (payload) {
        state.entitiesParent = payload;
      }
    },
    POPULATE_SIGNDATA: (state, payload) => {
      if (payload) {
        state.signData = payload;
      }
    },
    POPULATE_SIGNTERM: (state, payload) => {
      if (payload) {
        state.signTerms = payload;
      }
    },
  },
  namespaced: true,
  state: {
    children: null,
    combo: null,
    edit: null,
    entities: [],
    entitiesParent: [],
    entity: null,
    home: null,
    new: [],
    newChildren: null,
    patient_history: undefined,
    signData: null,
    signTerms: [],
  },
};

CommonState.registerModule('patient', module);
