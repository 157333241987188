const enUS = {
  btn: {
    accept: 'I accept',
    accept_and_sign: 'I accept and Sign up',
    access_appointment: 'Access Appointment',
    add: 'Add',
    apply: 'Apply',
    archive: 'Archive messages',
    back: 'Back',
    cancel: 'Cancel',
    cancel_payment: 'Cancel payment',
    clear: 'Clear',
    close: 'Close',
    close_room: 'Close Service',
    confirm: 'Confirm',
    confirm_payment: 'Confirm Payment',
    confirmSchedule: 'Confirm',
    copyToClipboard: 'Copy the code to<br/>Clipboard',
    cycleVideo: 'Cycle Video',
    delete: 'Delete',
    edit: 'Edit',
    emergency_entry: 'Entry in emergency room',
    emergency_leave: 'Leave room',
    enable_keyboard: 'Enable keyboard',
    entry: 'Access',
    filter: 'Filter',
    finish: 'Finish',
    finish_protocol: 'Finish Protocol',
    forward: 'Forward',
    free_attendance: 'Free Attendance',
    get_out: 'Get out line',
    logout: 'Logout',
    network: 'Test network',
    next: 'Next',
    no: "I don't",
    not_accept: "I don't accept",
    ok: 'Ok',
    pay: 'Pay',
    payInLocal: 'Confirm Payment in Local',
    prescriptionDigital: 'Prescription digital',
    reactivate: 'Reactivate',
    register: 'Register',
    register_accept: 'Accept and Register',
    register_signin: 'Register',
    remove: 'Remove',
    reply: 'Reply',
    request: 'Request',
    requestReturn: 'Request Return',
    resendMailMedic: 'Resend Email',
    resendMailPacient: 'Resend Email',
    save: 'Save',
    send: 'Send',
    sendFile: 'Send File',
    show_terms_use: 'View Terms of Use',
    sign_up: 'Sign Up',
    space: 'Space',
    start: 'Start',
    start_attendance: 'Start',
    unarchive: 'Unarchive messages',
    video: 'Video',
    waitingroom: 'Waiting Room',
    yes: 'Yes',
  },
  btnTitles: {
    chat_close: 'Close Messages / Files',
    chat_open: 'Open Messages / Files',
    countTimeAttendance: 'Session Duration',
    leave: 'End Call',
    mic_off: 'Connect Microphone',
    mic_on: 'Turn off Microphone',
    more_options: 'More Options',
    prescription: 'Prescription',
    print: 'Send Screen Photo',
    rec_on: 'Start Recording',
    rec_stop: 'End Recording',
    send: 'Send File',
    share: 'Share Screen',
    video_off: 'Connect Video',
    video_on: 'Turn off Video',
  },
  country: {
    AD: 'Andorra',
    AE: 'United Arab Emirates',
    AF: 'Afghanistan',
    AG: 'Antigua and Barbuda',
    AI: 'Anguilla',
    AL: 'Albania',
    AM: 'Armenia',
    AN: 'Netherlands Antilles',
    AO: 'Angola',
    AQ: 'Antarctica',
    AR: 'Argentina',
    AS: 'AmericanSamoa',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AX: 'Aland Islands',
    AZ: 'Azerbaijan',
    BA: 'Bosnia and Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Belgium',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'Saint Barthelemy',
    BM: 'Bermuda',
    BN: 'Brunei Darussalam',
    BO: 'Bolivia, Plurinational State of',
    BR: 'Brazil',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BW: 'Botswana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Cocos (Keeling) Islands',
    CD: 'Congo, The Democratic Republic of the Congo',
    CF: 'Central African Republic',
    CG: 'Congo',
    CH: 'Switzerland',
    CI: "Cote d'Ivoire",
    CK: 'Cook Islands',
    CL: 'Chile',
    CM: 'Cameroon',
    CN: 'China',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cape Verde',
    CX: 'Christmas Island',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DE: 'Germany',
    DJ: 'Djibouti',
    DK: 'Denmark',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    DZ: 'Algeria',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egypt',
    ER: 'Eritrea',
    ES: 'Spain',
    ET: 'Ethiopia',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falkland Islands (Malvinas)',
    FM: 'Micronesia, Federated States of Micronesia',
    FO: 'Faroe Islands',
    FR: 'France',
    GA: 'Gabon',
    GB: 'United Kingdom',
    GD: 'Grenada',
    GE: 'Georgia',
    GF: 'French Guiana',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Greenland',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GQ: 'Equatorial Guinea',
    GR: 'Greece',
    GS: 'South Georgia and the South Sandwich Islands',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HN: 'Honduras',
    HR: 'Croatia',
    HT: 'Haiti',
    HU: 'Hungary',
    ID: 'Indonesia',
    IE: 'Ireland',
    IL: 'Israel',
    IM: 'Isle of Man',
    IN: 'India',
    IO: 'British Indian Ocean Territory',
    IQ: 'Iraq',
    IR: 'Iran, Islamic Republic of Persian Gulf',
    IS: 'Iceland',
    IT: 'Italy',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordan',
    JP: 'Japan',
    KE: 'Kenya',
    KG: 'Kyrgyzstan',
    KH: 'Cambodia',
    KI: 'Kiribati',
    KM: 'Comoros',
    KN: 'Saint Kitts and Nevis',
    KP: "Korea, Democratic People's Republic of Korea",
    KR: 'Korea, Republic of South Korea',
    KW: 'Kuwait',
    KY: 'Cayman Islands',
    KZ: 'Kazakhstan',
    LA: 'Laos',
    LB: 'Lebanon',
    LC: 'Saint Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    LY: 'Libyan Arab Jamahiriya',
    MA: 'Morocco',
    MC: 'Monaco',
    MD: 'Moldova',
    ME: 'Montenegro',
    MF: 'Saint Martin',
    MG: 'Madagascar',
    MH: 'Marshall Islands',
    MK: 'Macedonia',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongolia',
    MO: 'Macao',
    MP: 'Northern Mariana Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexico',
    MY: 'Malaysia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'New Caledonia',
    NE: 'Niger',
    NF: 'Norfolk Island',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Netherlands',
    NO: 'Norway',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'New Zealand',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'French Polynesia',
    PG: 'Papua New Guinea',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Poland',
    PM: 'Saint Pierre and Miquelon',
    PN: 'Pitcairn',
    PR: 'Puerto Rico',
    PS: 'Palestinian Territory, Occupied',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RS: 'Serbia',
    RU: 'Russia',
    RW: 'Rwanda',
    SA: 'Saudi Arabia',
    SB: 'Solomon Islands',
    SC: 'Seychelles',
    SD: 'Sudan',
    SE: 'Sweden',
    SG: 'Singapore',
    SH: 'Saint Helena, Ascension and Tristan Da Cunha',
    SI: 'Slovenia',
    SJ: 'Svalbard and Jan Mayen',
    SK: 'Slovakia',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Suriname',
    SS: 'South Sudan',
    ST: 'Sao Tome and Principe',
    SV: 'El Salvador',
    SY: 'Syrian Arab Republic',
    SZ: 'Swaziland',
    TC: 'Turks and Caicos Islands',
    TD: 'Chad',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tajikistan',
    TK: 'Tokelau',
    TL: 'Timor-Leste',
    TM: 'Turkmenistan',
    TN: 'Tunisia',
    TO: 'Tonga',
    TR: 'Turkey',
    TT: 'Trinidad and Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzania, United Republic of Tanzania',
    UA: 'Ukraine',
    UG: 'Uganda',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VA: 'Holy See (Vatican City State)',
    VC: 'Saint Vincent and the Grenadines',
    VE: 'Venezuela, Bolivarian Republic of Venezuela',
    VG: 'Virgin Islands, British',
    VI: 'Virgin Islands, U.S.',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis and Futuna',
    WS: 'Samoa',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'South Africa',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
  label: {
    actions: 'Actions',
    additional_minute_recorded_allowed: 'Allow additional',
    additional_minute_value: 'Additional Value',
    additional_minutes_allowed: 'Allow additional',
    additional_recorded_minute_value: 'Additional Value',
    address: 'Address',
    adress: 'Address',
    any_appointments: "There aren't any appointments",
    appointment_duration: 'Time in minutes for standard query time, used for scheduling',
    appointment_reason: 'Scheduled Emergency Care',
    attendance: 'Attendace',
    attendance_average_minutes: 'Average Mining Count',
    attendance_average_minutes_recorded: 'Average Recording Minutes',
    attendance_average_minutos: 'Average frequency minutes',
    attendance_average_minutos_recorded: 'Average frequency minutes recorded',
    attendance_value: 'Service Value',
    attention: 'Attention!',
    audio: 'Audio',
    available_forwarding: 'Available Forwarding',
    available_specialty: 'Available Specialties',
    bill_type: 'Type',
    billing_data: 'Billing data',
    bio: 'Bio',
    birth_date: 'Date of Birth',
    business_contact: 'Business contact',
    by_period: 'By period',
    camera: 'Camera',
    cancel_reason: 'Cancel Reason',
    cancel_time: 'Cancel Date / Time',
    cell_phone: 'Mobile',
    change_password: 'Change password',
    chat: 'Chat',
    cid10: 'CID10',
    city: 'City',
    close: 'Close',
    cnpj: 'CNPJ',
    comission_type: 'Commission type',
    comission_value: 'Commission amount',
    comments: 'Comments',
    commission: 'Commission',
    company_id: 'Company',
    complaint: 'Complaint',
    complement: 'Complement',
    confirm: 'Confirm',
    connections: 'Session details',
    consultation_detail: 'Consultation detail',
    consultation_link: 'Consultation link',
    consultation_time: 'Total consultation duration',
    consultation_time_doctor: 'Total professional time in the care room',
    consultation_time_patient: 'Total patient time in the care room',
    contact: 'Contact number',
    country_contact: 'Country code',
    countryname: 'Country',
    cpf: 'CPF',
    credit_card: 'Credit Card',
    credit_card_security_code: 'CVV',
    crm: 'CRM',
    crmState: 'UF do CRM',
    DadosPessoais: 'Personal Data',
    dataConfirm: 'Confirm data',
    date: 'Date',
    date_valid_credit_card: 'Credit Card Validity',
    day: 'Day',
    days: 'Days',
    description: 'Description',
    discount: 'Discount',
    doctor: 'Professional (s)',
    doctor_id: 'Professional',
    dont_show_informations: `I don't want to see this information anymore`,
    duration: 'Duration',
    duration_days: 'Duration Days',
    edit: 'Edit',
    edit_address: 'Edit address',
    edit_data: 'Edit data',
    elective: 'Elective',
    emergency: 'Emergency',
    emergency_attended_now: "You're about to be attended by <b>{0}</b>!",
    emergency_attended_now_without_doctor_name: 'You will be served now!',
    emergency_attended_now_without_doctor_name_inclusive: 'You will be served now!',
    emergency_attended_redirect_seconds: 'You will be redirected to:',
    emergency_attended_redirect_seconds_inclusive: 'You will be redirected to:',
    emergency_attended_seconds:
      'In {0} seconds you will be redirected to the attendance room. If you prefer, just click on the butoon bellow',
    emergency_back_home: 'Homepage',
    emergency_complaint: 'Attendance Reason',
    emergency_dashboard_seconds:
      'In {0} seconds you will be redirected to dashboard. If you prefer, just click on the butoon bellow',
    emergency_finished: 'Your attendance was finished',
    emergency_finished_by_manager: 'Your attendance was finished',
    emergency_init_btn: 'NEW ATTENDANCE',
    emergency_init_new: 'In case you want to begin a new attendance',
    emergency_is_offline: 'Emergency is offline!',
    emergency_line_position: 'You are <strong> {0}th </strong> in the queue <strong> {1} </strong>',
    emergency_professional_attended_now: 'Você iniciará seu atendimento agora!',
    emergency_return: 'In case you want to return',
    emergency_return_btn: 'RETURN TO ATTENDANCE',
    emergency_return_click_btn: 'please hit the button <strong> {0} </strong> bellow.',
    emergency_service: 'Emergency Service',
    emergency_start_attendance: 'Start Attendance',
    emergency_still_active: 'Your attendance is still active',
    emergency_wait: 'Please wait...',
    emergency_wait_line: 'In few more seconds you will be attended',
    end_and_accomplish: 'Exit and Finish',
    end_appointment_question: 'Do you want to leave and finish the service?',
    end_date: 'End Date',
    end_date_time: 'End Date / Time',
    end_out: 'Just leave',
    end_time: 'End Time',
    end_time_waiting_room_attendance: 'The appointment time has passed by:',
    endereco: 'Address',
    enter_service: 'Enter service',
    entered: 'Entered',
    error_prescription: 'Prescription Unavailable',
    expiration_date: 'Expiration Date',
    field_required: '* Field Required',
    file: 'Files',
    forward: 'Forward',
    forward_patient: 'Select the queue and fill in the notes to forward the patient',
    foto: 'Foto',
    gender: 'Sex',
    goodAfternoon: 'Good Afternoon',
    goodEvening: 'Good Evening',
    goodMorning: 'Good Morning',
    groups: 'Groups',
    hello: 'Hello,',
    hello_header_emergency_professional: 'Welcome to the checkin of emergency attendance!',
    helloHeader: 'Hello',
    history: 'History',
    history_item_type_APPOINTMENT: 'Attendance',
    history_item_type_ARCHIVE: 'Archive',
    history_item_type_DIAGNOSTIC: 'Exam',
    history_item_type_EVENT: 'Event',
    history_item_type_EXAM: 'Exam',
    history_item_type_LINK: 'Link',
    history_item_type_NOTES: 'Notes',
    history_item_type_PRESCRIPTION: 'Prescription Digital',
    history_item_type_QUESTIONNAIRE: 'Request',
    history_type_event: 'Event Type',
    hour: 'Hours',
    hours: 'Hours',
    id: 'id',
    initial_attendance_quantity: 'Initial Service Quantity',
    installment: 'Installment',
    is_landing: 'Available at landing page?',
    item: 'Item',
    landing_page: 'Landing page',
    left: 'Out',
    license_council: 'License Council',
    limit: 'Limit',
    link: 'Link',
    load_more: 'See more ...',
    mail: 'E-mail',
    make_your_registration: 'Make your registration',
    max_additional_minute: 'Maximum Additional',
    max_additional_recorded_minute: 'Maximum Additional',
    max_attendance_minutes: 'Maximum participation minutes',
    medical_assistent: 'Assistant Physician',
    medical_specialty: 'Specialty',
    medicines: 'Medicines',
    message: 'Message',
    messages: 'Messages',
    messages_history: 'Message History',
    mic: 'Microphone',
    minutes: 'Minutes',
    minutes_consultation: 'Answering Minutes',
    minutes_quantity: 'Number of minutes',
    minutes_value: 'Minutes value',
    months: 'month',
    more_details: 'More Details',
    my_informations: 'My Informations',
    name: 'Name',
    name_card: 'Full name (Same as written on the card)',
    neighborhood: 'Bairro',
    newAddress: 'Save as default address',
    next_appointments: 'Next Appointment',
    no_data_available: 'No data available',
    no_data_medical_for_patient: 'There are no medical records available for this patient',
    notes: 'Notes',
    notes_appointment: 'Service notes',
    notes_optional: 'Notes (optional)',
    now: 'NOW',
    nps_finish_seconds: 'In {0} seconds you will be redirected to home. If you prefer, just click on the butoon bellow',
    number: 'Number',
    number_card: 'Credit card number',
    number_of_installments: 'Number of installments',
    observation: 'Observation',
    one_file: 'File(s)',
    orientation: 'Guidelines to Patient',
    other: 'OTHERS',
    other_informations: 'Other Informations',
    other_participant: 'Other Participants',
    out: 'Exit',
    out_appointment_question: 'Do you want to leave the service?',
    parent: 'Parent Company',
    parent_access_clinical_data: 'Share clinical data with user responsible?',
    parent_id: 'Responsible',
    participants: 'Participants',
    patient: 'Patient',
    patient_data: 'Pacient Data',
    patient_document: 'Document: {0}',
    patient_id: 'Patient (s)',
    patient_name: 'Name: {0}',
    payment: 'Payment',
    payment_confirmed: 'PPayment Confirmed',
    payment_confirmed_appreciate: 'We thank you for your trust and preference',
    payment_method: 'Payment Method',
    period: 'Period',
    permalink: 'Custom link',
    personaldata: 'Personal Data',
    pix: 'PIX (Brazilian Payment)',
    place: 'Place',
    place_text: 'Insert text here...',
    plan: 'Plan',
    plan_id: 'plan ID',
    plandata: 'Plan Data',
    please_fill_address: "Fill in patient's address data",
    please_register: 'Fill in your data and register',
    powered_by_dav: 'Developed with ❤ by Doutor ao Vivo',
    praise: 'Praise',
    pre_evaluation: 'Pre-Evaluation',
    prescription_data: 'Prescription Data',
    prescription_description_generate: 'Prescription by {0}',
    prescription_include_address_field: 'Include Address',
    prescription_settings: 'medical documents note',
    prescription_virtual_include_address: 'Include address in digital prescription note?',
    profession: 'Profession',
    professional_id: 'Doctor',
    professional_registration: 'Professional Registration',
    protocol: 'Protocol',
    quantity: 'Quantity',
    ranges: 'Tracks',
    rating: 'Rating',
    reason: 'Main Complaint',
    record_start: 'Audio and video recording of participants started ...',
    record_stop: 'Stopping the participants audio and video recording ...',
    recording_consultation: 'Recording Attendance',
    recordings: 'Recordings',
    region: 'UF',
    register_dependent: 'Register Dependent',
    registration: 'Registration',
    registration_state: 'Registration State',
    remetent: 'Remetent',
    representative: 'Representative',
    representative_id: 'representative ID',
    result_description: 'Result',
    resume: 'Purchase Summary',
    role: 'Cargo',
    room_active: 'You are in the waiting room for your Teleconsultation.',
    satisfaction_form_title_message: 'Thank you for participating and answering our satisfaction form!',
    save_address_default: 'Save this informations as default',
    save_note_appointment: 'Saving Service Notes ...',
    save_orientation_appointment: 'Saving Service Guidelines to Patient ...',
    save_reason_appointment: 'Saving Service Main Complaint ...',
    saving: 'Saving ...',
    scheduler: 'Scheduler',
    schedules: 'Schedules',
    search: 'Search',
    seconds: 'Seconds',
    select: 'Select',
    select_patient: 'Select a patient',
    select_the_professional: 'Select the professional',
    select_the_specialty: 'Select the specialty',
    select_user: 'Select a user',
    service_queue: 'Service queue',
    services: 'Services',
    size: 'Size',
    social_name: 'Social Name',
    source: 'Channel',
    source_options: {
      clinic: 'Clinic',
      emergency: 'Emergency',
      patient: 'Self scheduling',
    },
    specialty: 'Specialty',
    start_attendance: 'A Teleconsulta vai iniciar em:',
    start_date: 'Start Date',
    start_date_time: 'Start Date / Time',
    start_time: 'Start Time',
    status: 'Status',
    status_appointment: 'Appointment Status',
    street: 'Backyard',
    suggestion: 'Suggestion',
    telephone: 'Telephone',
    term_of_use_accept: 'Terms of use accept',
    term_use_agree_with: 'I have read and agree with ',
    text_term_use_accept: 'I have read and agree with <a href="{0}" target="_blank" rel="noreferrer nofollow">{1}</a>',
    text_term_use_login:
      'The {0} has updated the <b rel="noreferrer nofollow">Terms of Use</b>. We are engaged in protect our users security and privacy.',
    time_course: 'Time Course',
    timeToSchedule: 'Schedule',
    timezone: 'Timezone',
    title: 'Title',
    total: 'Total',
    try_again_later: 'Please, try again later!',
    type: 'Type',
    uhc_id: 'Universal Healthcare ID',
    upshot: 'Upshot',
    use_voucher: 'Do you have a voucher?',
    use_voucher_value: 'Enter the coupon code',
    value: 'Value',
    value_attendance: 'Service Value',
    value_discount: 'Discount Amount',
    value_paid: 'Amount',
    video: 'Video',
    view_messages: 'View Messages',
    wait_other_participant:
      'Please wait for another participant to enter the room for the audio and video resources to be released',
    wait_room_subtitle: 'You are in the waiting room for your Teleconsultation',
    wait_room_subtitle2: 'You are in the waiting room for your {0} Teleconsultation',
    warning: 'Warning',
    welcome_home: 'welcome to the office of',
    years: 'year (s)',
    zip_code: 'CEP',
  },
  message: {
    access_denied: 'Access Denied',
    after_register_dependent:
      'To be able to view or log in with your registered dependent, please click on the logout button and log in again',
    alert_queue_finsh: 'You are leaving the queue, do you really want to continue with this action?',
    appointmentDescription: 'Care for {0} patient {1} and professional {2} on {3}',
    appointmentTitleDate: 'at {0}',
    appointmentTitlePAT: 'Scheduled care for {0}, patient {2} and professional {1}',
    attendance_accomplish: 'Attendance Completed by Physician',
    attendance_finished: 'Patient care has been completed',
    attendance_needs_payment: 'In order to access attendance, you should pay it.',
    attendance_save_failure: 'Unable to save your schedule, please try again',
    billing_data_message: 'Fill in your billing details by clicking the edit button above',
    browser_deny_camera: 'Access to the camera has been blocked, please allow access so that the service can continue.',
    browser_not_suport: 'This browser does not support the service room, please access from another browser.',
    cancel_the_service: 'Are you sure you want to cancel the service?',
    change_user: 'Change user',
    chat_checkin: '{0} joined',
    chat_checkout: '{0} left',
    choice_dependent: 'Select the patient who will be treated',
    choose_option_attendance: 'Choose the next step. If no action is taken, the protocol will be terminated in {0}',
    chooseOption: 'Choose the option you want to perform',
    confirm_exit_alert: 'Are you sure you want to leave? Your data will not be saved.',
    confirm_sign_out: 'Are you sure?',
    connectionFail:
      'It will not be possible to enter the service room, please check if the appointment is still valid.',
    copySuccess: 'Copied successfully',
    cupom_valid: 'Discount applied successfully',
    dialog_pix_code: 'Identifying your transaction with a unique id',
    dialog_pix_confirmation_button: 'Press <strong>Confirm Payment</strong> button to close this window',
    dialog_pix_copy_01: 'Copy the transaction ID',
    dialog_pix_copy_02: 'In your bank app, go to the Pix area',
    dialog_pix_copy_03: 'Choose the <strong>Pix Copy and Paste</strong> option',
    dialog_pix_copy_04: 'Paste code that was copied',
    dialog_pix_finish_transaction: 'Confirm the information and finalize transaction',
    dialog_pix_instructions_header: 'Instructions to pay with id ID',
    dialog_pix_instructions_header_qr_code: 'Instructions to pay with QR Code',
    dialog_pix_qr_code_01: 'In your bank app, go to the Pix area',
    dialog_pix_qr_code_02: 'Click <strong>Pay</strong> and select the option <strong>Read QR Code</strong>',
    dialog_pix_qr_code_03: 'Point your cell phone camera at the QR Code',
    dialog_pix_your_payment_expires: 'Your PIX payment expires on ',
    dialog_prescription_error: 'To proceed with the prescription, register your address in your professional profile',
    dialog_prescription_error_international_address:
      'Only Brazilian addresses are allowed to generate prescription, make sure your address is located in Brazil',
    dialogCancelAppointmentPayment: 'Do you really want to cancel the payment?',
    dialogCancelRangeTitle: 'Confirm the cancellation',
    dialogCancelSelectText: 'Are you sure you want to cancel?',
    dialogDeleteRangeText: 'Are you sure you want to delete this track?',
    dialogDeleteRangeTitle: 'Confirm the deletion',
    dialogDeleteSelectText: 'Are you sure you want to delete?',
    entered_another_location: 'Dear, you were disconnected because you entered another location again.',
    error_kioski_navigation: 'Navigation Error. Close your browser and click on the original link.',
    field_is_required: '{0} is required.',
    file_added_successfully: 'File added successfully',
    file_already_exists: 'This file has already been added to query, please check the list and try again',
    file_delete_successfully: 'File deleted successfully',
    file_limited_size: 'The limit size of each file for upload is {0}MB',
    file_send_timeout: 'Could not send the file, please try again',
    fill_in_and_click_next: 'Fill in the information and click "<strong>next</strong.".',
    formErrorText: 'Inconsistent data.',
    formErrorTitle: 'Error',
    formSuccessText: 'It was done successfully.',
    inactive_room:
      'Hi, your session seems to be inactive for a long time, click the button below to continue with the session, or the it will be closed in {0} seconds',
    invalidDate: 'Invalid Birth date.',
    invalidMail: 'It must be a valid email.',
    isRequired: '{0} is required.',
    isUnique: '{0} already exists',
    isValid: '{0} is invalid.',
    kioski_finish: 'Thank you for using our service',
    loading: 'Loading',
    make_the_payment: 'Click here to make the payment',
    maxLength: '{0} must be a maximum of {1} characters.',
    maxLength_observation: '{0} deve ter no máximo {1} caracteres.',
    minLength: '{0} must be at least {1} characters.',
    my_availability: 'My Availability',
    my_profile: 'My Profile',
    new_checkin_participant: '{0} entered the service room',
    new_checkout_participant: '{0} left the service room',
    new_msg_participant: '{0} sent a new chat message',
    no: 'No',
    no_access_benefits_club: 'You do not have access to the benefits club. Contact the clinic administrator.',
    no_participants: 'No participant is in the main video, please choose the main video participant.',
    no_patient: 'There are no pacients for this appointment.',
    noData: 'No data available',
    paid_attendance_success:
      'The payment for the consultation was successful! We sent an email with information about access to the service room',
    paid_inlocal: 'Payment must be made on site',
    participant_data_problem: "There was not possible to create prescription due to participants's data problems:",
    payment_failure: 'Payment was not accepted, check if the data provided is correct',
    payment_in_analysis: 'Payment is in analysis. We will send a notification once its confirmed',
    payment_not_confirmed: 'Payment not found. Please, make sure the bank transaction was successful.',
    payment_success: 'Payment Success',
    pix_instructions_header: 'Instructions',
    pix_instructions_line_01:
      'Be aware: <b>you will have 15 minutes</b> to do the transaction with your Bank App. Once the transaction is expired, you will need to cancel and redo the operation.',
    pix_instructions_line_02:
      'When you ready, click the button to start the transaction. Will be generated a PixCode and a QR Code',
    pix_instructions_line_03: 'Copy and Paste the code or use your bank app to read the QR Code.',
    pix_instructions_line_04: 'After the bank app work, click Confirm Transaction to check if the payment was correct.',
    pix_instructions_line_05: 'Have a nice attendance.',
    prescription_error: 'Error while opening prescription. Please, try again later.',
    registration_unallowed: 'Patients registration is not allowed by the company',
    request_appointment: 'Appointment Request',
    request_body:
      'Hi, I am in contact to request an appointment with the specialist from {0}.<br/> Hello: {1} {2} <br/> Note: {3}',
    request_return_appointment: 'Return Request',
    request_return_body:
      'Hi, Im contacting you to request a return schedule. <br/> <br/> <strong>First query data:</strong> <br/> <strong>Date: </strong> <span>{0}</span> <br/> <strong>Professional: </strong> <span>{1}</span> <br/> <strong>Specialty: </strong> <span>{3}</span> <br/> <br/> <strong>My contact: </strong> <span>{3} {4}</span> <br/> <strong>Observation: </strong> <span>{5}</span> ',
    required_billing_data_message: 'Please fill in your billing details',
    required_fields: 'Please make sure to respond all required fields',
    response_automatic:
      'Hi, our team will check availability and get back to you with date options. <br/> After the dates return, choose the best day and time and pay for the consultation. <br/> With the payment confirmed you will receive access for the day of the consultation.',
    response_return_automatic:
      'Hi, our team will check availability and get back to you with date options. <br/> After the dates return, choose the best day and time. <br/> After our team schedules your return, you will receive access for the day of the consultation.',
    room_accomplish: 'Your appointment was finished',
    room_end: 'You have left your appointment',
    saving_prescription: 'Saving prescription file... Please wait.',
    service_guideline:
      'This service has the guidelines below. To access them during the consultation, click on the star icon in the consultation room menu.',
    sign_out: 'Sign Out',
    termPendingAccept: 'Terms of use of the system pending reading and acceptance.',
    voucher_not_apply: 'Voucher not apply',
    wiping_prescription: 'Wiping prescription... Please Wait.',
    without_audio_video: 'Cam and microphone were not enabled.',
    yes: 'Yes',
  },
  pages: {
    appointment: {
      detail: 'Appointment details',
      edit: 'Edit Appointment',
      list: 'Appointments',
      list_age: 'Schedule',
      list_all: 'My Attendances',
      list_today: 'Schedule',
      new: 'New Appointment',
    },
    benefit_club: 'Benefit club',
    cancel: {
      attendance_confirm: 'Your attendance{0} due to {1} is being confirmed and participants are:',
      confirm_title: 'Confirm cancel',
      invalid:
        'This attendance cannot be canceled because at the moment. Please get in contact with the company to perform the cancel through other channels',
      is_payment: 'For canceling your appointment, please contact the company.',
      payment_free: 'For canceling your appointment, please click on the button bellow',
      reason: 'Cancel reason',
      reason_describe: 'Describe cancel reason',
      success_text: 'Your attendance{0} due to {1} is canceled. The participants would be:',
      unable: 'Unable to cancel',
    },
    choiceDependent: {
      title: 'Choosen patient'
    },
    company: {
      Detail: 'Details',
      Edit: 'Edit',
      List: 'Companies',
      New: 'New Company',
    },
    dependent: {
      title: 'Register Dependent',
    },
    doctor: {
      edit: 'Edit Profile',
    },
    doctoroffice: {
      end: 'End of Service.',
      payment: 'Payment',
      room: 'Consultório',
    },
    drawner: {
      change_user: 'Change user',
      my_profile: 'My Profile',
      register_dependent: 'Register Dependent',
    },
    emergency: {
      arrivalInfo:
        'Perform your query simply and quickly Select a <b class="font-weight-bold">queue</b> and click <b class="font-weight-bold">next</b>.',
      arrivalTitle: 'Available queues',
      attendance_now: 'Attendance Now',
      check_resources_confirm:
        'After performing the camera test and reading the recommendations, click <b class="font-weight-bold">next</b> to access the queue',
      check_resources_recommend: {
        battery: 'Make sure your cell phone has enough charge',
        headphones: 'If it is possible, please use headphones',
        light: 'Make sure you are in a place with proper lighting',
        noise: 'Avoid agitated and noisy places',
        polite: 'Please, be polite to the professional that will attend you',
        video: 'Verify your camera and audio resources are working',
        wifi: 'Check if you have a good internet connection',
      },
      dashboard: {
        actions: 'Actions',
        groups: 'Queues',
        info: {
          document: 'Document',
          patient: 'Patient',
          position: 'Position',
          telephone: 'Telephone',
        },
        patients: 'Patients',
      },
      emergency: 'Virtual Line',
      emergency_greeting:
        'The following form you can write the attendance complaint and add medical tests or relevant files. After this, press the button "NEXT"',
      emergency_reason: 'Describe the reason for consultation',
      emergency_start:
        'Your service is starting, wait the estimated time or click <b class="font-weight-bold">Start service </b>.',
      emergencyWelcome: 'Welcome to emergency.',
      fill_nps: 'NPS',
      flow_group: 'Queue',
      flow_steps: {
        attendance: 'Attendance',
        collection_of_vital_signs: 'Collection of vital signs',
        orientations: 'Orientations',
        payment: 'Payment',
        rank: 'Rank',
        reason: 'Reason',
        resources: 'Check Camera',
        start_button_scouting: 'Click the <strong>"Start"</strong> button to begin scouting.',
      },
      general: 'General',
      next: 'Next',
      next_attendance: 'Next attendance',
      no_professional_emergency_groups: 'No queue to check in, check your access with Manager',
      orientation_steps: {
        attendance: 'Access your attendance.',
        orientation_title: 'Guidelines for accessing the Virtual Queue',
        orientations: 'To start access, click next.',
        orientations_group: 'To start access, select a service queue and click next.',
        payment: 'Set the payment.',
        rank: 'Wait for your attendance.',
        reason: 'Report the reason of your attendance, and attach your files.',
        resources: 'Test your audio/video resources',
      },
      patient_dispensing: 'Patient Dispensing',
      person_post_attendance_subtitle:
        'Please, wait professional decision. In few seconds you will be moved to the next step',
      person_post_attendance_title: 'Your attendance is getting finished...',
      post_attendance: 'Post Attendance',
      professional_emergency_checkin: 'Please click at the button bellow to start attendance in the {0} queue',
      professional_emergency_greeting: "Welcome to emergency's room checkin page",
      professional_emergency_groups: 'Select the queues you would like to access',
      protocol_finished: 'Your attendance was closed by the manager. Please, try to contact the clinic',
      reason_finished_label: 'Reason: {0}',
      start_emergency: 'To start access, click next.',
      start_emergency_group: 'Select a queue and hit the next button to start flow',
      user_protocol: 'Your protocol is <strong> {0} </strong>',
      virtual_line: 'Virtual Line',
    },
    home: 'Home page',
    ladingpage: {
      home: 'Home',
      list_professionals: 'Ours Experts',
      professional_profile: 'Professional Profile',
    },
    login_page: {
      back: 'Back',
      birthdate_is_required: 'Birth date is required.',
      close: 'Close',
      confirm: 'Confirm',
      continue: 'Continue',
      dont_have_account: 'Don`t have an account yet?',
      empty_mail: 'Email cannot be empty',
      enter_your_data: 'Enter your data',
      forgot_password: 'Forgot my password',
      forgot_password_message: 'We will send a recovery link to your user',
      forgot_your_password: 'Forgot your password?',
      invalid_birthdate: 'Invalid birth date.',
      invalid_cpf: 'Invalid CPF.',
      invalid_date_of_birth: 'Invalid date of birth.',
      invalid_email: 'Invalid email',
      invalid_password: 'Invalid password',
      invalid_universal_healthcare_id: 'Invalid Universal Healthcare ID.',
      log_in: 'Log in',
      login_to_your_account: 'Login to your account',
      message_forgot_mail: 'A new password was sent to the email provided',
      message_forgot_mail_error: 'Unable to send a new password to the email provided',
      new_password: 'New password',
      password: 'Password',
      password_confirm: 'Confirmation must be the same as the chosen password',
      password_min_length: 'The password must be at least 8 characters long',
      required_cpf: 'CPF is required.',
      send_mail: 'Send Email',
      type_your_birthdate: 'Type your birth date',
      type_your_cpf: 'Type your CPF',
      type_your_email: 'Type your email',
      type_your_password: 'Type your password',
      type_your_universal_healthcare_id: 'Type your Universal Healthcare ID',
      user_not_found: 'User not found',
      valid_password:
        'The password must be at least 8 characters long and consist of uppercase, lowercase letters and numbers',
    },
    message: {
      archive: 'Archive',
      archivedSuccess: 'Successfully archived.',
      body: 'Body',
      detail: 'Mensagem',
      inbox: 'Inbox',
      list: 'Messages',
      new: 'Nova mensagem',
      new_message: 'New message',
      new_message_success: 'Message sent successfully',
      new_response: 'New response',
      no_items_selected: 'No items selected',
      notifications: 'Notifications',
      reply_message: 'Reply message',
      response_success: 'Response successfully sent',
      seen: 'Views',
      select_an_item: 'Select an item to read',
      subject: 'Subject',
      type_a_message: 'Type a message',
      unarchivedSuccess: 'Successfully unarchived.',
    },
    nps: {
      _likedTitle: 'What you liked the most?',
      _suggestionsTitle: 'Suggestions for improvements:',
      are_you_still_evaluating: 'Are you still evaluating?',
      closing_session_in_seconds: 'Closing session in {0}...',
      listBadFeedbacks: {
        confusing_system_to_use: 'Confusing system to use',
        difficulty_hearing_seeing_the_doctor: 'Difficulty hearing/seeing the doctor',
        doctors_lack_of_patience: 'Doctor`s lack of patience',
        lack_of_doctor_education: 'Lack of doctor education',
        problem_with_the_system: 'Problem with the system',
        the_doctor_didnt_listen_see_me: 'The doctor didn`t listen/see me',
        waiting_time: 'Waiting time',
      },
      listGoodFeedbacks: {
        audio_and_video_quality: 'Audio and video quality',
        doctors_education: 'Doctor`s education',
        doctors_patience: 'Doctor`s Patience',
        easy_to_use_the_system: 'Easy to use the system',
        waiting_time: 'Waiting time',
      },
      message: 'On a scale of 0 to 10, how much would you recommend teleconsultation to a friend or family member?',
      message_logout: 'Are you sure you want to leave? Your review has not been sent.',
      your_review_has_been_successfully_sent: 'Your review has been successfully sent!',
    },
    pageNotFound: 'Page not found',
    patient: {
      choose_picture: 'Choose picture',
      edit: 'Edit Profile',
      ehr: 'Electronic Health Record',
      remove_picture: 'Remove picture',
    },
    person: {
      schedule: 'Schedule Appointment',
    },
    personHome: {
      benefit_club: 'Benefit Club',
    },
    plan: {
      detail: 'Plan',
      edit: 'Edit plan',
      list: 'Plans',
      new: 'New plan',
    },
    professional: {
      detail: 'Professional Details',
      edit: 'Edit Professional',
      list: 'Professional',
      new: 'New Professional',
    },
    request: {
      description: 'Fill this form to open your request',
      stepText1: 'Select desired specialty to request an appointment',
      stepText2: 'Fill in your mobile number',
      stepText3: 'If needed, fill in the Note field with any additional details',
      stepText4: 'Click on REQUEST and wait for our return with the dates available for scheduling',
      title: 'Request Scheduling',
    },
    requestReturn: {
      description: 'First query data',
      stepText1: 'Review the requested return query data',
      stepText2: 'Fill in your mobile number and if needed, fill in the Note field with any additional details',
      stepText3: 'Click on REQUEST and wait for our return with the dates available for scheduling',
      title: 'Request return',
    },
    schedule: {
      action: 'Action',
      allDay: 'Full Day',
      allows_patient_to_schedule: 'Specify deadline for making appointments available',
      at: 'at',
      attachExams: 'If you want to attach exams, click the send button and select the desired file.',
      attachFiles: 'Exams added',
      blocked: 'Schedule blocking',
      cancel: 'Cancel',
      click_here_for_attendance: 'Click here for service now',
      completeFinish: 'Click the finish button to complete',
      date: 'Scheduling Date',
      dateEnd: 'End Date',
      dateSchedule: 'Schedule Date',
      dateStart: 'Start Date',
      day: 'Day',
      days: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
      },
      delete: 'Delete',
      describeText: {
        confirm: 'Confirm the appointment details and click <strong>next</strong>',
        final:
          'Appointment scheduled successfully, click on the <strong>finish</strong> button to be redirected to the home page',
        payment: 'Make payment for your service',
        reason: 'Briefly describe the reason for your visit and attach exams',
        selectDayHour:
          'Select the <strong>day</strong> and <strong>time</strong> of the appointment and click <strong>next</strong>',
        speciality:
          'Schedule your appointment simply and quickly. <br /> Select a <strong>specialty</strong> and click <strong>next</strong>',
      },
      errors: {
        already_exists: 'Date already exists',
        appointments_conflicts: 'Attendance Conflicts',
        greater_limit_days: 'Date range cannot be greater than 35 days',
        invalid_date: 'Invalid date',
        invalid_time: 'Invalid time',
        less_current_date: 'Date must be greater than current one',
        less_selected_start_date: 'Insert a date greater than the date selected in the calendar',
        less_start_time: 'End Time must be greater than start time',
        out_of_schedule: 'Selected time is out of schedule',
        registered_appointments: 'Attention! There are registered appointments for this time!',
        time_conflict: 'Time conflicts!',
        time_default: 'Time must me be greater than standard attendance time',
        wont_be_canceled: 'Any appointments will be excluded. It must be done manually.',
      },
      extra: 'Extraordinary',
      filesSend: 'Wait ... File being sent',
      finished: 'Consultation successfully scheduled',
      fixed_schedule: 'Fixed Schedule',
      hello: 'Hello',
      hourEnd: 'Hora Fim',
      hourStart: 'Start Time',
      ignoreHolidays: 'Skip holidays at the clinic',
      loadingSchedule: 'Searching for available dates and times',
      medical_specialty: 'Specialty',
      my_availability: 'My Availability',
      new: 'New',
      noTimes: 'No Hours Available on the Selected Day',
      notSchedule: 'No time available for the month of {0}. Please consult another month.',
      per: 'Per',
      professional: 'Professional',
      reason: 'What are you feeling?',
      scheduled: 'Scheduled',
      selectDay: 'Select the day of the consultation',
      selectHourProfessional: 'Select the schedule of the professional',
      textHeaderForwardedIn: 'Forwarded in',
      textNotHaveSpecialtySub:
        'It is necessary to go to the emergency room and be evaluated by the professional on duty. <br/> Click on the button below the paw to be redirected',
      textNotHaveSpecialtyTitle: 'You have no referrals',
      textStepConfirm: 'Scheduling',
      textStepExams: 'Your appointment has been scheduled for {0}',
      textStepExamsText: 'with the specialist in {0}',
      textStepForwardingText1: 'Choose the forwarding',
      textStepReason: 'Please tell us the reason for your appointment and click next.',
      textStepSelectDay: 'Choose the day and time',
      textStepSpecialty: 'Schedule your appointment simply and quickly.',
      textStepSpecialtyText1: 'Choose specialty',
      textStepSpecialtyText2: 'Choose the day and time',
      textStepSpecialtyText3: 'Briefly describe the reason for the consultation',
      textStepSpecialtyText4: 'Confirm the data',
      textStepSpecialtyText5: 'Attach exams (if any)',
      textStepSpecialtyText6: 'You can attach images or pdf',
      textStepSpecialtyText7: 'To start scheduling, choose the desired specialty below and click next.',
      textStepSpecialtyTextPayment: 'Confirm payment',
      textStepTitleConfirm: 'Confirmation',
      textStepTitleExams: 'Exams',
      textStepTitleFinal: 'Final',
      textStepTitleForwarding: 'Forwarding',
      textStepTitlePayment: 'Payment',
      textStepTitleReason: 'Reason',
      textStepTitleSelectDay: 'Date/Time',
      textStepTitleSpecialty: 'Specialty',
      time: 'Schedule',
      timeAllowed: 'Allow Patient to Schedule',
    },
    screening: {
      artificial_intelligence: {
        example: 'Example:',
        instructions: {
          analyzing_complaint: 'Analyzing your complaint, please wait!',
          ask_questions: 'Now, I am going to ask you some questions',
          confirm_said: 'You said:',
          describe: 'Briefly describe the symptoms you are experiencing and how long ago they started.',
          example: '"I have had a sore throat and fever for 2 days."',
          lets_try_again: 'Lets try again',
          more_ask_questions: 'Looking at your answers',
          speak: 'Click on microphone and speak briefly about the symptoms you are experiencing and how long ago they started.',
          speak_understand: 'I cant understand, lets try again!',
          thanks_subtitle: 'You will now be forwarded to the service queue.',
          thanks_title: 'Thank you for the information',
        },
        steps: {
          answer_questions: 'Answer the questions and click <strong>"send"</strong> when finished',
          confirm_audio: 'Click <strong>"microphone"</strong> to start',
          confirm_text: 'Click <strong>"send"</strong> when finished',
          intro: 'Click the <strong>"start"</strong> button to begin screening',
        },
      },
      required: 'Required field',
    },
    use_term: {
      not_accept_term: 'It will not be possible to access the system without accepting the terms of use',
    },
    waitingroom: {
      room: 'Waiting room',
    },
  },
  periods: {
    '7_days': '7 days',
    '15_days': '15 days',
    '30_days': '30 days',
  },
  testConnectivity: {
    audio: {
      bad: 'Audio connectivity bad',
      fair: 'Audio connectivity reasonable',
      good: 'Audio connectivity good',
      testing: 'Testing the Audio',
    },
    connection: {
      failure: 'Conection Test Failure',
      OK: 'Successfully connected to our services',
      testing: 'Testing Connection...',
    },
    error: {
      APIConnectivityError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      ConnectivityError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      ConnectToSessionError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      ConnectToSessionNetworkError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      ConnectToSessionSessionIdError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      ConnectToSessionTokenError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      FailedMessagingServerTestError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      FailedToCreateLocalPublisher:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      FailedToObtainMediaDevices:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      helpCamera:
        '- Check your browsers address bar if access to the camera is blocked <span class="material-icons" style="vertical-align: bottom;color: #000;">videocam_off</span>, click on the icon and give permission ',
      helpCameraConfig:
        '- Check your browser settings if the camera is blocked for this application and grant permission',
      helpCameraDupliciteUse:
        '- Check if the camera is being used by another application, close them all and access again',
      helpCameraLink:
        '- For other cases, <a href="https://suporte.doutoraovivo.com.br/hc/pt-br/articles/360048709214-PERMITIR-ACESSO-A-C%C3%82MERA-NO-NAVEGADOR" target="_blank" rel="noreferrer nofollow">click here</a>',
      IncompleteSessionCredentialsError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      InitPublisherError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      InvalidOnUpdateCallback:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      LoggingServerConnectionError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      MediaDeviceError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      MissingOpenTokInstanceError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      MissingSessionCredentialsError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      MissingSubscriberError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      NetworkTestError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      NoAudioCaptureDevicesError: 'We are unable to access your microphone, we need this access to the Call Center.',
      NoVideoCaptureDevicesError: 'We are unable to access your camera, we need this access to the Call Center.',
      PublishToSessionError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      PublishToSessionNetworkError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      PublishToSessionNotConnectedError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      PublishToSessionPermissionOrTimeoutError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      QualityTestError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      SubscriberGetStatsError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      SubscribeToSessionError:
        'We are unable to access your camera and/or microphone, we need this access to the Call Center.',
      title: 'Possible Corrections:',
      UnsupportedBrowser: 'Unsupported Internet browser',
    },
    failureDetails: 'See details',
    video: {
      bad: 'Video connectivity bad',
      fair: 'Video connectivity reasonable',
      good: 'Video connectivity good',
      testing: 'Testing the Video',
    },
  },
  title: {
    appointment: {
      cid10: 'Inform CID10',
      description: 'Service Description',
      doctor: 'Professionals',
      doctor_id: 'Professional',
      end_date: 'End Date',
      end_date_time: 'End Date / Time of Service',
      end_time: 'End Time',
      medical_assistent: 'Assistant Physician',
      notes: 'Service Notes',
      patient_id: 'Patient',
      role: 'Participant Type',
      start_date: 'Start Date',
      start_date_time: 'Service Start Date / Time',
      start_time: 'Start Time',
      status_appointment: 'Appointment Status',
      title: 'Service Title',
      type: 'Type of Service',
      upload_image: 'Image uploader',
      upshot: 'Inform upshot of attendance',
    },
    company: {
      birth_date: 'Date of Birth',
      business_contact: 'Business contact',
      cell_phone: 'Mobile',
      city: 'City',
      cnpj: 'CNPJ',
      comission_type: 'Commission type',
      comission_value: 'Commission amount',
      company_id: 'Company',
      complement: 'Complement',
      cpf: 'CPF',
      edit: 'Edit',
      expiration_date: 'Expiration Date',
      gender: 'Sex',
      mail: 'E-mail',
      name: 'Fantasy Name',
      neighborhood: 'Bairro',
      number: 'Number',
      parent_id: 'Person financially responsible for the patient',
      photo: 'Photo',
      plan_id: 'plan ID',
      region: 'Federal Unit',
      representative_id: 'representative ID',
      social_name: 'Social Reason',
      status: 'Status',
      street: 'Rua, Avenida ...',
      telephone: 'Telephone',
      zip_code: 'CEP',
    },
    doctor: {
      birth_date: 'Date of Birth',
      cell_phone: 'Mobile',
      city: 'City',
      company_id: 'Company',
      complement: 'Complement',
      cpf: 'CPF',
      crm: 'CRM',
      crmState: 'State of the Regional Council of Medicine',
      Filter: 'Filter',
      gender: 'Sex',
      id: 'Identifier',
      mail: 'E-mail',
      medical_specialty: 'Medical Specialty',
      name: 'Name',
      neighborhood: 'Bairro',
      number: 'Number',
      picture: 'Photo',
      region: 'Federal Unit',
      status: 'Status',
      street: 'Rua, Avenida ...',
      telephone: 'Telephone',
      zip_code: 'CEP',
    },
    patient: {
      address: 'Address',
      birth_date: 'Date of Birth',
      cell_phone: 'Mobile',

      city: 'City',
      company_id: 'Company',
      complement: 'Complement',
      country: 'Country',
      cpf: 'CPF',
      gender: 'Sex',
      mail: 'E-mail',
      name: 'Name',
      neighborhood: 'Bairro',
      number: 'Number',
      parent_id: 'Person financially responsible for the patient',
      profession: "Patient's profession",
      region: 'Region',
      status: 'Status',
      street: 'Rua Avenida ...',
      telephone: 'Telephone',
      zip_code: 'CEP',
    },
    plan: {
      additional_minute_recorded_allowed: 'Indicates whether this plan allows additional minutes for recording',
      additional_minute_value: 'Value of the additional minute for consulting the plan',
      additional_minutes_allowed: 'Indicates whether this plan allows additional minutes for consultation',
      additional_recorded_minute_value: 'Value of the additional minute of recording the plan',
      attendance_average_minutes: 'Average number of consultation minutes for contract verification',
      attendance_average_minutes_recorded: 'Average number of minutes of recording for contract verification',
      attendance_value: 'Consultation Value',
      bill_type: 'Plan type',
      commission_type: 'Commission Type',
      commission_value: 'Commission Amount',
      description: 'Plan Name',
      duration_days: 'Indicates the number of days the plan lasts',
      initial_attendance_quantity: 'Initial number of queries for this range',
      max_additional_minute: 'Maximum number of additional minutes for consultation',
      max_additional_recorded_minute: 'Maximum number of additional minutes for recording',
      max_attendance_minutes: 'Indicates the maximum duration of minutes of the consultation',
      minutes_quantity: 'Number of minutes of consultation of the plan',
      minutes_value: 'Plan consultation minute value',
      name: 'Plan Name',
      recorded_minute_value: 'Plan recording minute value',
      recorded_minutes_quantity: 'Number of minutes of recording the plan',
    },
    professional: {
      birth_date: 'Date of Birth',
      cell_phone: 'Mobile',
      city: 'City',
      company_id: 'Company',
      complement: 'Complement',
      cpf: 'CPF',
      crm: 'CRM',
      Filter: 'Filter',
      gender: 'Sex',
      id: 'Identifier',
      mail: 'E-mail',
      medical_specialty: 'Medical Specialty',
      name: 'Name',
      neighborhood: 'Bairro',
      number: 'Number',
      photo: 'Photo',
      region: 'Federal Unit',
      role: 'Job Title',
      status: 'Status',
      street: 'Rua, Avenida ...',
      telephone: 'Telephone',
      zip_code: 'CEP',
    },
    request: {
      contact: 'Contact number',
      observation: 'Observation',
      specialty: 'Specialty',
    },
    settings: {
      allows_patient_to_schedule_time_before: 'Time before the scheduled time is allowed',
      appointment_duration: 'Average session time in minutes',
      period: 'Period',
    },
  },
  value: {
    active: 'Active',
    inactive: 'Inactive',
    no: 'No',
    yes: 'Yes',
  },
};

export default enUS;
